.App-home {
    position: relative;
}

.App-home #intro.BookishContainer .BookishContainer-inner {
    padding: 50px 0px 30px 0px;
}

.App-home footer {
    margin: 8px 0px 0px 0px;
}

@media screen and (min-width: 1140px) {
    .App-home #intro.BookishContainer .BookishContainer-bottom-right {
        background-color: transparent !important;
    }

    .App-home #intro.BookishContainer .BookishContainer-inner {
        padding: 35px 0px 50px 0px;
    }
}