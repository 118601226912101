.App-header {
    position: relative;
    background-color: #ffffff;
    height: 120px;
    padding: 20px 28px 0px 28px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    display: block;
}
  
.App-header-logo {
    display: inline-block;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
  
.App-header-logo img {
    height: 48px;
    cursor: pointer;
}

.App-header-logo span {
    cursor: pointer;
}
  
.App-header-logo span {
    display: block;
    font-size: 15pt;
    margin-top: 6px;
}
  
.App-header-burgermenu {
    position: absolute;
    display: block;
    top: 14px;
    right: 22px;
    background-color: #ffffff;
    z-index: 99999;
}

.App-header-burgermenu img {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.App-header-burgermenu img.normal {
    display: inline;
}

.App-header-burgermenu img.hover {
    display: none;
}

.App-header-burgermenu:hover img.normal, .App-header-burgermenu.active img.normal {
    display: none;
}

.App-header-burgermenu:hover img.hover, .App-header-burgermenu.active img.hover {
    display: inline;
}

.App-header-burgermenu:active {
    transform: scale(0.9);
}

.App-header-navbar {
    display: none;
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: transparent;
    z-index: 9999;
}

.App-header-navbar.active {
    display: block;
}

.App-header-navbar-before {
    display: block;
    text-align: right;
    line-height: 0;
    font-size: 0;
}

.App-header-navbar-before-inner {
    display: inline-block;
    height: 90px;
    width: 1px;
    background-color: #000000;
    margin: 0px 25px 0px 0px;
}

.App-header-navbar-inner {
    background-color: #ffffff;
    border: 1px solid #000000;
    padding: 35px 0px 20px 40px;
    min-width: 340px;
}

.App-header-navbar ul {
    list-style-type: square;
    color: #95A6B1;
    font-size: 24pt;
    margin-bottom: 20px;
}

.App-header-navbar ul br {
    display: none;
}

.App-header-navbar li {
    position: relative;
    padding: 5px 0px 5px 0px;
}

.App-header-navbar ul li a {
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
    line-height: 1;
    font-family: 'eurostile-condensed', 'Assistant', sans-serif;
    font-weight: 400;
    color: #000000;
    text-decoration: none !important;
}

.App-header-navbar ul li a.active {
    font-family: 'eurostile-condensed', 'Assistant', sans-serif;
    font-weight: 800;
}

.App-header-navbar-indicator {
    display: inline-block;
    vertical-align: top;
    background-color: #F7A823;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 12pt;
    width: 24px;
    height: 24px;
    padding-top: 2px;
    margin: 2px 0px 0px 2px;
    border-radius: 100%;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
}

.App-header-navbar-language {
    padding: 2px 3px 1px 3px;
    text-align: left;
}

.App-header-navbar-language .ToggleSwitch {
    vertical-align: top;
    display: inline-block;
}

@media screen and (min-width: 1140px) {
    .App-header {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 2px solid #D5DBE0;
    }

    .App-header-logo {
        position: absolute;
        top: 20px;
        left: 28px;
        z-index: 99999;
    }

    .App-header-burgermenu {
        display: none;
    }

    .App-header-navbar {
        display: block;
        position: absolute;
        left: 0;
        right: 30;
        top: 0;
    }

    .App-header-navbar-before {
        display: none;
    }

    .App-header-navbar-inner {
        border: none;
        min-width: 0;
        max-width: 1140px;
        margin-left: auto;
        margin-right: auto;
    }

    .App-header-navbar ul {
        list-style-type: none;
        font-size: 18pt;
        padding: 0;
        margin: 0;
        text-align: right;
    }

    .App-header-navbar ul br.App-header-navbar-br-posscreen {
        display: inline;
    }

    .App-header-navbar li {
        display: inline-block;
        text-align: right;
        vertical-align: top;
        padding: 0;
        line-height: 1;
    }    

    .App-header-navbar ul li a {
        margin-top: 0px;
        line-height: 1.1;
    }

    .App-header-navbar ul li a.active {
        color: #F7A824;
    }

    .App-header-navbar li.App-header-navbar-li-services {
        text-align: left;
        vertical-align: bottom;
        min-width: 195px;
    }

    #root.en .App-header-navbar li.App-header-navbar-li-services {
        min-width: 145px;
    }

    /*
    #root.en .App-header-navbar li.App-header-navbar-li-services {
        min-width: 205px;
    }
    */

    .App-header-navbar li.App-header-navbar-li-references {
        text-align: left;
        vertical-align: bottom;
        min-width: 245px;
    }

    .App-header-navbar li.App-header-navbar-li-posscreen {
        text-align: left;
        vertical-align: bottom;
        min-width: 185px;
    }
    
    /* .App-header-navbar li.App-header-navbar-li-career {
        vertical-align: bottom;
        min-width: 200px;
    } */

    /* .App-header-navbar li.App-header-navbar-li-companyhistory {
        display: none;
    } */

    .App-header-navbar li.App-header-navbar-li-team {
        text-align: left;
        min-width: 195px;
    }

    #root.en .App-header-navbar li.App-header-navbar-li-team {
        min-width: 145px;
    }

    .App-header-navbar li.App-header-navbar-li-companyhistory {
        text-align: left;
        min-width: 245px;
    }

    .App-header-navbar li.App-header-navbar-li-contact {
        text-align: left;
        min-width: 125px;
    }

    /* #root.en .App-header-navbar li.App-header-navbar-li-contact {
        min-width: 164px;
    } */

    .App-header-navbar-indicator {
        display: block;
        position: absolute;
        right: -18px;
        top: -2px;
        font-size: 10pt;
        width: 18px;
        height: 18px;
        padding-top: 2px;
        margin: 0;
    }

    .App-header-navbar-language {
        position: absolute;
        padding: 0;
        right: 0px;
        top: 10px;
    }
    
    .App-header-navbar-language .ToggleSwitch-value {
        font-size: 12pt;
        height: 20px; /* 30px *2/3 */
    }
    
    .App-header-navbar-language .ToggleSwitch-inner {
        margin: 0px 5px 0px 7px; /* 0px 8px 0px 10px *2/3 */
    }
    
    .App-header-navbar-language .ToggleSwitch-input {
        height: 20px; /* 30px *2/3 */
        width: 44px; /* 66px *2/3 */
    }
    
    .App-header-navbar-language .ToggleSwitch-input + label::before {
        top: 4px; /* 6px *2/3 */
        left: 7px; /* 10px *2/3 */
        height: 12px; /* 18px *2/3 */
        width: 30px; /* 45px *2/3 */
        border-radius: 6px;
    }
      
    .App-header-navbar-language .ToggleSwitch-input + label::after {
        height: 20px; /* 30px *2/3 */
        width: 20px; /* 30px *2/3 */
    }
      
    .App-header-navbar-language .ToggleSwitch-input:checked + label::after {
        left: 23px; /* 35px *2/3 */
    }
}

@media screen and (min-width: 1240px) {
    .App-header-navbar {
        right: 0;
    }

    .App-header-navbar-language {
        right: 16px;
        top: 11px;
    }
}

@media screen and (min-width: 1500px) {
    .App-header-navbar-language {
        right: 18px;
        top: auto;
        bottom: 14px;
    }
}