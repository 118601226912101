.App-home-services {
    position: relative;
}

.App-home #services.BookishContainer {
    overflow: hidden;
}

.App-home-services-area {
    position: relative;
    margin: 40px 0px 80px 0px;
    padding: 0px 14px 0px 14px;
}

.App-home-services-area-3 {
    margin-bottom: 160px;
}

.App-home-services-area-border {
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0.7;
}

.App-home-services-area-1 .App-home-services-area-border {
    width: 50px;
}

.App-home-services-area-2 .App-home-services-area-border {
    width: 150px;
}

.App-home-services-area-3 .App-home-services-area-border {
    width: 190px;
}

.App-home-services-area-bubble {
    position: absolute;
    top: 0;
    width: 350px;
    height: 350px;
    border-radius: 175px;
    margin-top: -60px;
    background-color: #B5C1C9;
}

.App-home-services-area-inner {
    position: relative;
}

.App-home-services-area-inner-header {
    font-size: 30pt;
    padding: 12px 0px 12px 0px;
}

.App-home-services-area-inner-text {
    max-width: 320px;
}

.App-home-services-area.left {
    text-align: left;
}

.App-home-services-area.right {
    text-align: right;
}

.App-home-services-area.left .App-home-services-area-border {
    left: 0;
    border: 2px dashed #FFFFFF;
    border-right: none;
    border-bottom: none;
}

.App-home-services-area.right .App-home-services-area-border {
    right: 0;
    border: 2px dashed #FFFFFF;
    border-left: none;
    border-bottom: none;
}

.App-home-services-area.left .App-home-services-area-bubble {
    left: 0;
    margin-left: -120px;
}

.App-home-services-area.right .App-home-services-area-bubble {
    right: 0;
    margin-right: -120px;
}

.App-home-services-area-1.right .App-home-services-area-bubble {
    margin-right: -240px;
}

.App-home-services-area-2.left .App-home-services-area-bubble {
    margin-left: -140px;
}

.App-home-services-area.left .App-home-services-area-inner-text {
    margin-right: auto;
}

.App-home-services-area.right .App-home-services-area-inner-text {
    margin-left: auto;
}

@media screen and (min-width: 1140px) {
    .App-home-services-area {
        margin: 0px;
    }

    .App-home-services-area-1 {
        margin-top: 70px;
    }
    
    .App-home-services-area-3 {
        margin-bottom: 190px;
    }

    .App-home-services-area-inner-text {
        max-width: 440px;
    }

    .App-home-services-area-1.right .App-home-services-area-bubble {
        top: -20px;
    }
}