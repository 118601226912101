.App-home-posscreen {
    
}

.App-home-posscreen-header {
    margin-bottom: 40px;
}

.App-home-posscreen-logo-container {
    padding-bottom: 40px;
    font-size: 0;
}

.App-home-posscreen-logo {
    margin: 0px 0px 40px 0px;
}

.App-home-posscreen-logo img {
    height: 90px;
    margin-left: 2px;
}

.App-home-posscreen-logo img.mobile {
    display:inline;
}

.App-home-posscreen-logo img.desktop {
    display:none;
}

.App-home-posscreen-logo-text {
    margin-top: 15px;
    font-size: 18pt;
}


@media screen and (min-width: 1140px) {
    .App-home-posscreen-header {
        margin-bottom: 50px;
    }

    .App-home-posscreen-logo {
        display: inline-block;
        background-color: #F5F6F7;
        padding: 30px 15px 15px 15px;
        border-radius: 20px;
        width: calc(33.33% - 20px * 2 / 3);
        height: 240px;
        vertical-align: top;
        text-align: left;
        margin-left: 20px;
    }

    .App-home-posscreen-logo:first-child {
        margin-left: 0px;
    }

    .App-home-posscreen-logo img {
        height: 60px;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    .App-home-posscreen-logo img.mobile {
        display:none;
    }

    .App-home-posscreen-logo img.desktop {
        display:inline;
    }

    .App-home-posscreen-logo-text {
        margin-top: 15px;
        padding-left: 2px;
        padding-right: 2px;
        font-size: 18px;
    }

}