.App-services {
    position: relative;
}

.App-services-top-right-corner {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -30px;
    right: 0;
    background-color: #95A6B1;
}

.App-services-top-right-corner-inner {
    width: 30px;
    height: 30px;
    border-bottom-right-radius: 30px;
    background-color: #FFFFFF;
}

.App-services-intro {
    padding: 20px 0px 90px 0px;
    text-align: center;
}

.App-services-intro img {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.App-services-leistungsdreieck-container {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 30px 10px 0px;
    text-align: right;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.App-services-crossplatform {
    padding-bottom: 10px;
}

.App-services-crossplatform-header {
    margin-bottom: 12px;
}

.App-services-crossplatform-item {
    display: inline-block;
    margin-right: 22px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: #000000;
}

.App-services-crossplatform-item:last-child {
    margin-right: 0px;
}

.App-services-crossplatform-item:hover {
    color: #424242;
}

.App-services-crossplatform-item:active {
    transform: scale(0.95);
}

.App-services-crossplatform-item img {
    height: 25px;
    margin-right: 10px;
}

.App-services-crossplatform-item span {
    text-decoration: underline;
}

.App-services .BubbleContainer {
    padding-bottom: 30px;
}

.App-services-details {
    position: relative;
    padding: 20px 0px 20px 0px;
}

.App-services-details .left {
    padding-bottom: 30px;
}

.App-services-details .left img {
    width: 100%;
    object-fit: contain;
    border: 1px solid #000000;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.App-services .BookishContainer .ArrowButton-container {
    margin-top: 90px;
    text-align: right;
}

.App-services-design-references-header {
    margin: 10px 0px 35px 0px;
    font-size: 30pt;
}

.App-services-design-references-carousel {
    margin-bottom: 20px;
}

.App-services-design-references-carousel .Carousel-caption-container {
    padding-left: 0;
    padding-right: 0;
}

@media screen and (min-width: 950px) {
    .App-services-details .left {
        display: inline-block;
        vertical-align: top;
        width: 350px;
        padding-bottom: 0px;
    }
    
    .App-services-details .right {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        padding: 0px 0px 0px 75px;
    }

    .App-services-details .left + .right {
        width: calc(100% - 350px);
    }
}

@media screen and (max-width: 950px) {
    .App-services-design-references-carousel .Carousel-preview {
        display: none;
    }

    .App-services-design-references-carousel .Carousel-img-container-outer {
        margin-right: 0px !important;
    }
}

@media screen and (min-width: 1140px) {
    .App-services-top-right-corner {
        display: none;
    }

    .App-services #intro.BookishContainer .BookishContainer-header {
        padding-top: 97px;
    }

    .App-services-intro {
        padding: 0px 0px 73px 0px;
    }

    .App-services-intro img {
        position: absolute;
        top: 50px;
        right: 90px;
    }

    .App-services-leistungsdreieck-container {
        background-color: #F5F6F7;
        padding-right: 90px;
    }

    .App-services .BubbleContainer {
        background-color: #F5F6F7;
    }

   

    .App-services .BookishContainer .ArrowButton-container {
        margin-top: 50px;
    }
}