.App-references {
    position: relative;
}

.App-references-top-right-corner {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -30px;
    right: 0;
    background-color: #EBECF0;
}

.App-references-top-right-corner-inner {
    width: 30px;
    height: 30px;
    border-bottom-right-radius: 30px;
    background-color: #FFFFFF;
}

.App-references-intro {
    padding: 10px 20px 50px 20px;
}

.App-references-intro .animation .lf-player-container {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
}

.App-references-logo-container {
    position: relative;
    padding: 40px 0px 30px 0px;
    font-size: 0;
}

.App-references-logo {
    display: inline-block;
    text-align: center;
    width: 50%;
    padding-bottom: 20px;
}

.App-references-logo img {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    object-fit: contain;
    max-width: 180px;
}

.App-references-educationalsoftware {
    margin: 40px 0px 50px 0px;
    text-align: center;
}

.App-references-educationalsoftware-content .Carousel-caption-container {
    background-color: #95a6b1;
}

.App-references-educationalsoftware-header {
    margin: 0px 0px 20px 0px;
    font-size: 26pt;
}

.App-references-springerfachmedien {
    margin-bottom: 10px;
}

.App-references-springerfachmedien .Carousel {
    margin-bottom: 35px;
}

#mobileapp.BoxContainer .BoxContainer-box-icon img {
    width: 26px;
}

@media screen and (min-width: 1140px) {
    .App-references-top-right-corner {
        display: none;
    }

    .App-references #intro.BookishContainer .BookishContainer-header {
        padding-top: 97px;
    }

    .App-references-intro {
        padding: 0px 0px 73px 0px;
    }

    .App-references-intro .animation {
        position: absolute;
        top: 15px;
        right: 110px;
    }

    .App-references-intro .animation .lf-player-container {
        max-width: 315px;
    }

    .App-references-logo {
        width: 33.33%;
    }

    .App-references-logo img {
        max-width: 200px;
    }
}