.ArrowButton {
    position: relative;
    display: inline-block;
    color: #000000;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 0px;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    font-size: 24pt;
    line-height: normal;
    height: 54px;
    padding: 0px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: transparent;
}

.ArrowButton .text {
    display: inline-block;
    padding: 1px 26px 0px 27px;
    vertical-align: top;
}

.ArrowButton .arrow {
    display: inline-block;
    width: 52px;
    height: 52px;
    border-left: 1px solid #000000;
    border-bottom-right-radius: 20px;
    background-color: #ffffff;
}

.ArrowButton .arrow img {
    margin: 3px 0px 0px 0px;
}

.ArrowButton .arrow img.normal {
    display: inline;
}

.ArrowButton .arrow img.hover {
    display: none;
}

.ArrowButton:hover .arrow img.normal {
    display: none;
}

.ArrowButton:hover .arrow img.hover {
    display: inline;
}

.ArrowButton:active {
    transform: scale(0.95);
}