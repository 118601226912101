.BoxContainer {
    position: relative;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}

.BoxContainer-box {
    background-color: #000000;
    text-align: center;
    padding: 80px 0px 40px 0px;
}

.BoxContainer-box-icon {
    position: relative;
    display: inline-block;
    width: 76px;
    height: 76px;
    border-radius: 38px;
    background-color: #F7A824;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.BoxContainer-box-icon img {
    width: 34px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.BoxContainer-box-header {
    color: #FFFFFF;
    font-size: 24pt;
}

.BoxContainer-inner {
    padding: 30px 30px 30px 30px;
}

.BoxContainer-inner .escape {
    margin-top: -30px;
    margin-left: -30px;
    margin-right: -30px;
}

.BoxContainer-inner .escape-left {
    margin-left: -30px;
}

.BoxContainer-inner .escape-right {
    margin-right: -30px;
}

.BoxContainer-inner .escape-top {
    margin-top: -30px;
}

@media screen and (min-width: 1140px) {
    .BoxContainer-inner {
        padding: 30px 75px 30px 75px;
    }

    .BoxContainer-inner .escape {
        margin-top: -30px;
        margin-left: -75px;
        margin-right: -75px;
    }
    
    .BoxContainer-inner .escape-left {
        margin-left: -75px;
    }
    
    .BoxContainer-inner .escape-right {
        margin-right: -75px;
    }
    
    .BoxContainer-inner .escape-top {
        margin-top: -30px;
    }
}