.App-impressum {
    padding: 25px 30px 30px 30px;
}

.App-impressum p {
    font-size: 16pt;
    font-size: calc(1.2rem + 0.3vw);
    margin-bottom: 2rem;
}

.App-impressum p.center {
    text-align: center;
}