.Carousel-container {
    position: relative;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}

.Carousel-img-container-outer {
    position: relative;
    margin-right: 0px;
}

.Carousel-img-container {
    position: relative;
    width: 100%;
}

.Carousel-img-container-inner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.Carousel-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
}

.Carousel-arrow-left, .Carousel-arrow-right {
    position: absolute;
    cursor: pointer;
    top: 0px;
    bottom: 0px;
    width: 50px;
}

.Carousel-arrow-left {
    left: 0px;
}

.Carousel-arrow-right {
    right: 0px;
}

.Carousel-arrow-left img, .Carousel-arrow-right img {
    position: absolute;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    top: 50%;
    height: 40px;
    margin-top: -20px;
}

.Carousel-arrow-left img {
    left: 10px;
    opacity: 0.8;
}

.Carousel-arrow-right img {
    right: 10px;
    opacity: 0.8;
}

.Carousel-arrow-left img.normal, .Carousel-arrow-right img.normal {
    display: block;
}

.Carousel-arrow-left img.hover, .Carousel-arrow-right img.hover {
    display: none;
}

.Carousel-arrow-left:hover img.normal, .Carousel-arrow-right:hover img.normal {
    display: none;
}

.Carousel-arrow-left:hover img.hover, .Carousel-arrow-right:hover img.hover {
    display: block;
}

.Carousel-arrow-left:active img, .Carousel-arrow-right:active img {
    transform: scale(0.9);
}

.Carousel-caption-container {
    display: none;
    padding: 20px 30px 20px 30px;
}

.Carousel-caption-container.active {
    display: block;
}

.Carousel-indicator {
    position: absolute;
    left: 50px;
    right: 50px;
    bottom: 15px;
    text-align: center;
}

.Carousel-indicator-item {
    display: inline-block;
    background-color: #000000;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    width: 15px;
    height: 15px;
    margin-left: 4px;
    margin-right: 4px;
    opacity: 0.5;
}

.Carousel-indicator-item.active {
    background-color: #F7A823;
    opacity: 1;
}

.Carousel-preview {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}

.Carousel-preview-item {
    width: 100%;
    margin-top: 15px;
    cursor: pointer;
}

.Carousel-preview-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.Carousel-preview-item:hover img {
    -webkit-filter: none;
    filter: none;
}

.Carousel-preview-item:active {
    transform: scale(0.96);
}

.Carousel-preview-item-0 {
    margin-top: 0px;
}

.Carousel-preview.rows-1 .Carousel-preview-item {
    height: 100%;
}

.Carousel-preview.rows-2 .Carousel-preview-item {
    height: calc(50% - 7px);
    height: calc(50% - 15px / 2);
}

.Carousel-preview.rows-3 .Carousel-preview-item {
    height: calc(33.34% - 10px);
    height: calc(33.34% - 15px * 2 / 3);
}

.Carousel-preview.rows-4 .Carousel-preview-item {
    height: calc(25% - 11px);
    height: calc(25% - 15px * 3 / 4);
}

.Carousel-preview.rows-5 .Carousel-preview-item {
    height: calc(20% - 12px);
    height: calc(20% - 15px * 4 / 5);
}

.Carousel-preview.rows-6 .Carousel-preview-item {
    height: calc(16.67% - 12px);
    height: calc(16.67% - 15px * 5 / 6);
}

@media screen and (min-width: 1140px) {
    .Carousel-caption-container {
        padding: 20px 75px 20px 75px;
    }

    .Carousel-indicator {
        bottom: 20px;
    }

    .Carousel-indicator-item {
        border-radius: 12px;
        width: 20px;
        height: 20px;
    }
}