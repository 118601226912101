.App {
  display: block;
  height: 100%;
	width: 100%;
  background-color: #ffffff;
}

.App-body {
  position: relative;
  min-height: calc(100% - 120px);
  background-color: #ffffff;
}