.App-home-intro {

}

.App-home-intro-animation {
    margin: 80px 0px 10px 0px;
}

.App-home-intro-animation .lf-player-container {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 1140px) {
    .App-home-intro-slogan {
        display: inline-block;
        text-align: left;
        margin: 110px 0px 0px 30px;
    }

    .App-home-intro-animation {
        margin: 0px 5px 0px 0px;
        float: right;
    }
    
    .App-home-intro-animation .lf-player-container {
        max-width: 500px;
    }
}

@media screen and (min-width: 1240px) {
    .App-home-intro-slogan {
        margin-left: 0px;
    }

    .App-home-intro-animation {
        margin-right: -10px;
    }
}