.BubbleContainer {
    position: relative;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
}

.BubbleContainer-bubble {
    position: absolute;
    left: -40px;
    top: 0;
    width: 160px;
    height: 160px;
    border-radius: 80px;
    border: 3px solid #000000;
    background-color: #F7A824;
}

.BubbleContainer-bubble-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    left: calc(50% + 6px);
    transform: translate(-50%, -50%);
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.BubbleContainer-inner {
    padding: 55px 30px 30px 142px;
}

.BubbleContainer-header {
    font-size: 30pt;
    padding: 0px 0px 15px 0px;
    line-height: 1;
}

.BubbleContainer-left {
    display: none;
}

@media screen and (min-width: 1140px) {
    .BubbleContainer-left {
        display: block;
        position: absolute;
        width: 45px;
        left: -45px;
        top: 0px;
        bottom: 0px;
        background-color: #FFFFFF;
    }

    .BubbleContainer-inner {
        padding-left: 180px;
        padding-right: 90px;
    }
}