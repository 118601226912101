.App-home-team {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
}

.App-home #team.BookishContainer .BookishContainer-header {
    padding-bottom: 10px;
}

.App-home-team-carousel .Carousel-caption-container {
    background-color: #EBECF0;
    margin-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    border-bottom-right-radius: 30px;
}

.App-home-team-carousel .Carousel-caption-container .Carousel-caption-text {
    font-family: 'eurostile-condensed', 'Assistant', sans-serif;
    font-weight: 400;
    font-size: 16pt;
}

.App-home-team-gallery {
    position: relative;
    display: none;
    font-size: 0;
    padding-top: 50px;
    padding-bottom: 80px;
}

.App-home-team-gallery-row {
    margin-top: 2px;
}

.App-home-team-gallery-row:first-child {
    margin-top: 0px;
}

.App-home-team-gallery-item {
    position: relative;
    display: inline-block;
    width: calc(16.66% - 2px * 5 / 6);
    margin-left: 2px;
    background-color: #4E4E4E;
}

.App-home-team-gallery-item:first-child {
    margin-left: 0px;
}

.App-home-team-gallery-item img {
    width: 100%;
    object-fit: contain;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.App-home-team-gallery-item:hover img, .App-home-team-gallery-item.highlight img {
    -webkit-filter: none;
    filter: none;
}

.App-home-team-gallery-placeholder img {
    opacity: 0;
}

.App-home-team-gallery-item-inner, .App-home-team-gallery-placeholder-inner {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 11pt;
    padding: 25px 20px 15px 20px;
    color: #FFFFFF;
}

.App-home-team-gallery-item-inner {
    display: none;
    background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.0) 100%);
}

.App-home-team-gallery-item:hover .App-home-team-gallery-item-inner {
    display: block;
}

.App-home-team-gallery-item.highlight:hover .App-home-team-gallery-item-inner {
    display: none;
}

.App-home-team-gallery-placeholder-inner {
    top: 0;
    background-color: #4E4E4E;
}

.App-home-team-gallery-item-header, .App-home-team-gallery-placeholder-header {
    font-weight: bold;
}

.App-home-team-gallery-item-text, .App-home-team-gallery-placeholder-text {
    line-height: 1.5;
}

.App-home-team-gallery-placeholder-lottie {
    position: absolute;
    left: -5px;
    bottom: 0;
}

.App-home-team-gallery-placeholder-filltext h4 {
    padding-top: 30px;
    text-align: center;
    text-transform: uppercase;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.App-home-team-gallery-item.clickable {
    cursor: pointer;
}

.App-home-team-gallery-item.clickable:hover, .App-home-team-gallery-item.clickable:hover .App-home-team-gallery-placeholder-inner {
    background-color: #767676;
}

.App-home-team-gallery-item.clickable:active {
    transform: scale(0.92);
}

@media screen and (min-width: 1140px) {
    .App-home-team {
        max-width: none;
    }

    .App-home-team-carousel {
        display: none;   
    }

    .App-home-team-gallery {
        display: block;
    }
}