.Slogan {
    position: relative;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    text-align: center;
}

.Slogan-inline {
    display: inline-block;
}

.Slogan-inline > span {
    display: block;
    text-align: left;
    line-height: 1.1;
    letter-spacing: 5px;
}

.Slogan .eurostile-condensed-heavy {
    font-size: 55pt;
    margin: 0px 0px 0px 15px;
}

.Slogan .eurostile-condensed {
    font-size: 38pt;
    margin: 0px 0px 0px 110px;
}

.Slogan-inline-br-1 {
    display: none;
}

.Slogan-inline-br-2 {
    display: inline;
}

.App-slogan-container.BookishContainer {
    display: none;
}

.Slogan-inline-animation {
    display: none;
    position: absolute;
    left: 25px;
    top: -94px;
    width: 610px;
}

@media screen and (min-width: 1140px) {
    .Slogan .eurostile-condensed-heavy {
        font-size: 46pt;
        margin: 0px 0px 0px 0px;
    }

    .Slogan .eurostile-condensed {
        font-size: 34pt;
        margin: 0px 0px 0px 18px;
    }

    .Slogan-inline {
        text-align: left;
    }

    .Slogan-inline > span {
        display: inline;
        line-height: 1.0;
    }

    .Slogan-inline-br-1 {
        display: inline;
    }
    
    .Slogan-inline-br-2 {
        display: none;
    }

    .Slogan-inline-span-2 {
        padding: 0px 0px 0px 145px;
    }

    .App-slogan-container.BookishContainer {
        display: block;
    }
  
    .App-slogan-container.BookishContainer .BookishContainer-inner {
        padding: 15px 30px 20px 0px;
    }
  
    .App-slogan-container.BookishContainer .App-slogan {
        text-align: right;
    }
  
    .App-slogan-container.BookishContainer .App-slogan .eurostile-condensed-heavy {
        font-size: 36pt;
    }
  
    .App-slogan-container.BookishContainer .App-slogan .eurostile-condensed {
        font-size: 28pt;
    }
  
    .App-slogan-container.BookishContainer .App-slogan .Slogan-inline-span-2 {
        padding: 0px 0px 0px 120px;
    }
}
  
@media screen and (min-width: 1240px) {
    .App-slogan-container.BookishContainer .BookishContainer-inner {
        padding-right: 0px;
    }

    .Slogan-animation .Slogan-inline {
        opacity: 0;
    }

    .Slogan-animation .Slogan-inline-animation {
        display: block;
    }
}