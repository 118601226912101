.App-home-company-history {
    
}

.App-home-company-history-entry {
    margin: 65px 0px 0px 0px;
    font-size: 0;
}

.App-home-company-history-entry:last-child {
    margin-bottom: 65px;
}

.App-home-company-history-entry-left, .App-home-company-history-entry-right {
    display: inline-block;
    vertical-align: top;
}

.App-home-company-history-entry-left {
    text-align: right;
    padding: 75px 10px 0px 0px;
    width: 62px;
}

.App-home-company-history-entry-left.noimage {
    padding-top: 0px;
}

.App-home-company-history-entry-left span {
    display: inline-block;
    text-align: center;
    font-size: 19pt;
    line-height: 1.2;
}

.App-home-company-history-entry-right {
    width: calc(100% - 62px);
    border-left: 2px dashed rgba(255, 255, 255, 0.7);
    padding-left: 15px;
}

.App-home-company-history-entry-right-img img {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    height: 47px;
    margin-bottom: 28px;
}

.App-home-company-history-entry-right-header {
    margin: 0px 0px 23px 0px;
    font-size: 19pt;
    line-height: 1.2;
}   

.App-home-company-history-entry-right-text {
    font-size: 16pt;
}

.App-home-company-history-entry-right-text p {
    margin-bottom: 12px;
}

@media screen and (min-width: 1140px) {
    .App-home-company-history-entry-left {
        text-align: left;
        padding-top: 68px;
        width: 130px;
    }

    .App-home-company-history-entry-left.noimage {
        padding-top: 0px;
        margin-top: -6px;
    }

    .App-home-company-history-entry-right {
        width: calc(100% - 130px);
        padding-right: 20px;
    }

    .App-home-company-history-entry-left span {
        font-size: 25pt;
    }
}