html {
	height: 100%;
	width: 100%;
}

body {
  margin: 0;
  font-family: 'Assistant', sans-serif;
  font-weight: 400;
  font-size: 18pt;
  line-height: 1.3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  left: 0px;
	top: 0px;
	height: 100%;
	width: 100%;
  /* min-width: 500px; */
	padding: 0px !important;
  overflow-y: scroll;
  overflow-x: hidden;
  /*cursor: url("./media/mouse-0.png"), auto;*/
}

body.navbar-active {
  position: fixed;
}

@media screen and (min-width: 1140px) {
  body.navbar-active {
    position: static;
  }
}

#root {
  height: 100%;
	width: 100%;
}

.eurostile {
  font-family: 'eurostile', 'Assistant', sans-serif;
  font-weight: 400;
}

.eurostile-heavy {
  font-family: 'eurostile', 'Assistant', sans-serif;
  font-weight: 800;
}

.eurostile-condensed {
  font-family: 'eurostile-condensed', 'Assistant', sans-serif;
  font-weight: 400;
}

.eurostile-condensed-heavy {
  font-family: 'eurostile-condensed', 'Assistant', sans-serif;
  font-weight: 800;
}