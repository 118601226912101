.App-career {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.App-career-cmd {
    position: absolute;
    z-index: 1;
    top: 40px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    padding: 1px;
    font-size: 0;
    background-color: #707070;
    overflow: hidden;
}

.App-career-cmd-titlebar {
    width: 100%;
    height: 40px;
    padding: 7px 10px 0px 15px;
    font-size: 14pt;
    /* font-weight: bold; */
    background-color: #ffffff;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    pointer-events: none;
}

.App-career-cmd-titlebar span, .App-career-cmd-titlebar img {
    display: inline-block;
    vertical-align: top;
}

.App-career-cmd-titlebar span {
    margin-left: 6px;
}

.App-career-cmd-titlebar img {
    margin-top: 0px;
    height: 25px;
}

.App-career-cmd-inner {
    width: 100%;
    height: calc(100% - 40px);
    background-color: #0C0C0C;
    color: #A5B2BC;
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 15px;
    font-size: 12pt;
    line-height: 1.2;
}

.App-career-cmd-inner span.header {
    color: #FFFFFF;
}

.App-career-cmd-inner span.subheader {
    color: #FFFFFF;
    text-transform: uppercase;
    letter-spacing: 0.05em;
}

.App-career-cmd-inner span.command {
    color: #FFFFFF;
}

.App-career-cmd-inner span.ul {
    display: block;
    padding-left: 20px;
    list-style-position: outside;
    list-style-type: square;
}

.App-career-cmd-inner span.li {
    display: list-item;
    margin-bottom: 5px; 
}

.App-career-cmd-inner a {
    color: #FFFFFF;
}

.App-career-cmd-inner a:hover {
    color: #A5B2BC;
}

.App-career-cmd-inner a.karriere-at:hover {
    color: #8bc72a;
}

.App-career-cmd-inner a.linkedin:hover {
    color: #0a66c2;
}

.App-career-cmd-inner .cursor-blink {
    display: inline-block;
    width: 10px;
    margin: 0px 2px -2px 2px;
    border-bottom: 4px solid #CCCCCC;
    opacity: 1;
    animation: cursorblink 0.95s infinite;
}

@keyframes cursorblink {
    0% { opacity: 0 }
    30% { opacity: 1 }
    70% { opacity: 1 }
    100% { opacity: 0 }
}

.App-career-cmd-inner pre {
    margin-bottom: 0px;
    overflow: hidden;
}

.App-career-cmd-inner pre.help {
    font-size: 12pt;
}

.App-career-marquee {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 28px;
    background-color: #F7A824;
    color: #FFFFFF;
    font-size: 14pt;
    overflow: hidden;
}

.App-career-marquee-inner {
    position: absolute;
    top: 1px;
    left: 100%;
    padding: 0px 20px 0px 20px;
    white-space: nowrap;
    animation: marquee 30s linear infinite;
}

.App-career-marquee:hover .App-career-marquee-inner {
    animation-play-state: paused;
}

@keyframes marquee {
    0% {
      left: 100%;
    }
    100% {
      left: -2100px;
    }
}

#bone {
    display: none;
    position: absolute;
    left: 8px;
    bottom: 8px;
    font-size: 11pt;
    color: rgb(0,0,0);
    pointer-events: none;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}

#bone.delete-this-class-to-make-the-bone-visible {
    color: rgba(255,255,255,0);
}

@media screen and (min-width: 1140px) {
    .App-career-cmd {
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: calc(100% - 100px);
        max-height: 680px;
        width: 100%;
        max-width: 1140px;
        margin: 60px auto 40px auto;
    }

    .App-career-marquee {
        top: -2px;
    }

    .App-career-marquee-inner {
        animation-duration: 40s;
    }

    #bone {
        display: block;
    }
}